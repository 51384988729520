import React, { useState, useContext, useEffect, useCallback } from 'react';
import styles from './styles.module.css';
import ShopServices from '../../../services/ShopServices';
import Loader from '../../utils/Loading';
import { AlertMessagesContext } from 'react-alert-messages';
import { Grid, TextField, MenuItem, Button, FormControlLabel, Checkbox } from '@material-ui/core';
import withConsoleBase from '../../utils/ConsoleBase/withConsoleBase';
import { useParams, Link, useHistory } from 'react-router-dom';
import {
  ROUTES,
  SUBSCRIPTIONS,
  CURRENCIES,
  TERMINAL_TYPES,
  SUBSCRIPTION_IDS,
  SUBSCRIPTION_KEYS,
  ADDITIONAL_SALES,
  MONTHLY_SUB_PERIOD,
  YEARLY_SUB_PERIOD,
} from '../../../const';
import DealerService from '../../../services/DealerServices';
import TerminalService from '../../../services/TerminalServices';
import { Typography } from '@mui/material';

function NewShop() {
  const { postAlertMessage } = useContext(AlertMessagesContext);
  const { shopId } = useParams();
  const history = useHistory();

  const [loadingIndicator, setLoadingIndicator] = useState(false);
  const [data, setData] = useState({
    gst_preference: 'Including GST',
    currency: 'RS',
    subscription: {
      key: 'premium',
      validity: YEARLY_SUB_PERIOD,
      id: SUBSCRIPTION_IDS['premium'],
    },
    add_ons: {},
    additional_sales: null,
    terminal_type: TERMINAL_TYPES.pos_terminal,
    rt_sales_sync_interval: 60,
  });
  const [dealers, setDealers] = useState([]);
  const [terminals, setTerminals] = useState([]);
  const [addonTerminals, setAddonTerminals] = useState({});

  const handleAddonValidity = (key, validity) => {
    setData((prevData) => {
      const updatedAddOns = { ...prevData.add_ons };

      if (validity === 0 && !(key in updatedAddOns)) {
        return prevData;
      }

      if (!(key in updatedAddOns)) {
        updatedAddOns[key] = validity;
      } else {
        if (validity === 0) {
          updatedAddOns[key] = 0;
        } else {
          updatedAddOns[key] += validity;
        }
      }
      return {
        ...prevData,
        add_ons: updatedAddOns,
      };
    });
  };

  const handleSubscriptionValidity = (validity) => {
    setData((prevData) => {
      return {
        ...prevData,
        subscription: {
          ...prevData.subscription,
          validity: validity === 0 ? 0 : (prevData.subscription.validity || 0) + validity,
        },
      };
    });
  };

  const transformAddOns = (add_ons) => {
    return Object.entries(add_ons).map(([key, value]) => ({
      key,
      validity: value,
    }));
  };

  const prepareShopData = (data, addonTerminals) => {
    const { subscription, add_ons, additional_sales, terminal_type, mac_id, next_fid, ...rest } = data;

    let transformedAddOns = transformAddOns(add_ons ?? {});

    if (additional_sales) {
      transformedAddOns.push(additional_sales);
    }

    const expenseIndex = transformedAddOns.findIndex((addOn) => addOn.key === 'expense');
    if (subscription.key === 'premium') {
      if (expenseIndex === -1) {
        transformedAddOns.push({ key: 'expense', validity: subscription.validity });
      } else {
        transformedAddOns[expenseIndex].validity = subscription.validity;
      }
    } else if (expenseIndex !== -1) {
      transformedAddOns.splice(expenseIndex, 1);
    }

    if (terminal_type) {
      transformedAddOns.push({
        key: terminal_type,
        count: 1,
        mac_id: mac_id ?? null,
        next_fid: next_fid ?? null,
      });
    }

    if (Object.keys(addonTerminals).length) {
      const terminalCounts = {};

      Object.values(addonTerminals).forEach((type) => {
        terminalCounts[type] = (terminalCounts[type] || 0) + 1;
      });

      for (const [type, count] of Object.entries(terminalCounts)) {
        const key = type === 'pos' ? 'pos_terminal' : 'ecs_terminal';
        transformedAddOns.push({ key, count });
      }
    }

    return {
      ...rest,
      plan: {
        subscription,
        add_ons: transformedAddOns,
      },
    };
  };

  const handleSubmit = async () => {
    setLoadingIndicator(true);
    try {
      const shopData = prepareShopData(data, addonTerminals);

      if (!shopData.dealer_id) {
        postAlertMessage({
          text: 'Dealer is required',
          type: 'failed',
        });
        setLoadingIndicator(false);
        return;
      }

      if (shopId) {
        if (shopData.shop_logo) {
          delete shopData.shop_logo;
        }
        const resp = await ShopServices.updateShop(shopId, shopData);
        setData(resp);
        postAlertMessage({
          text: 'Shop updated successfully',
          type: 'success',
        });
      } else {
        const resp = await ShopServices.createShop(shopData);
        setData(resp);

        postAlertMessage({
          text: 'Shop created successfully',
          type: 'success',
        });
      }
    } catch (error) {
      postAlertMessage({ text: error?.message, type: 'failed' });
    }
    setLoadingIndicator(false);
  };

  const getShop = useCallback(async () => {
    setLoadingIndicator(true);
    try {
      const resp = await ShopServices.getShop(shopId);
      if (resp.data?.dealer) {
        resp.data.dealer_id = resp.data.dealer.id;
      }
      if (resp.data?.subscription) {
        resp.data.subscription = {
          key: SUBSCRIPTION_KEYS[resp.data.subscription],
          validity: 0,
        };
      }
      setData(resp.data);
    } catch (error) {
      console.error(error);
    }
    setLoadingIndicator(false);
  }, [shopId]);

  const handleCreateTerminal = () => {
    history.push(ROUTES.CREATE_TERMINAL.replace(':shopId', shopId));
  };

  const getDealers = async () => {
    setLoadingIndicator(true);
    try {
      const res = await DealerService.getDealers();
      setDealers(res?.data);
    } catch (error) {
      postAlertMessage({ text: error?.message, type: 'failed' });
    }
    setLoadingIndicator(false);
  };

  const getTerminals = useCallback(async () => {
    const resp = await TerminalService.getTerminals({ shop_id: shopId });
    setTerminals(resp?.data);
  }, [shopId]);

  const handleAdditionalTerminals = (terminal) => {
    setAddonTerminals((prev) => {
      if (addonTerminals.hasOwnProperty(terminal.name)) {
        const updatedTerminals = { ...prev };
        delete updatedTerminals[terminal.name];
        return updatedTerminals;
      } else {
        return { ...prev, [terminal.name]: terminal.type };
      }
    });
  };

  useEffect(() => {
    getDealers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (shopId) {
      getShop().then();
      getTerminals();
    }
  }, [shopId, getShop, getTerminals]);

  return (
    <div className={styles.contentWrapper}>
      <Loader isOpen={loadingIndicator} />
      <div className={styles.titleSec}>
        <h2 className={styles.title}>
          Shops<span className={styles.menuTitle}>Management</span>
        </h2>
        <Button variant="contained" color="primary" onClick={handleCreateTerminal}>
          Create Terminal
        </Button>
      </div>
      <div className={styles.changeable}>
        <div className={styles.filterSec}>
          <div className={styles.headTitle}>
            <h2 className={styles.subTitle}>Shop Details</h2>
          </div>
        </div>
        {data.id && (
          <Grid container className={styles.inputRow} alignItems="center">
            <Grid item xs={12} md={3} spacing={2} justifyContent="flex-end" className={styles.inputLabelContainer}>
              <h3 className={styles.inputLabel}>Shop Id</h3>
            </Grid>
            <Grid item xs={12} md={9}>
              <TextField variant="outlined" size="small" fullWidth type="text" disabled value={data?.id} />
            </Grid>
          </Grid>
        )}
        <Grid container className={styles.inputRow} alignItems="center">
          <Grid item xs={12} md={3} spacing={2} justifyContent="flex-end" className={styles.inputLabelContainer}>
            <h3 className={styles.inputLabel}>Shop name</h3>
          </Grid>
          <Grid item xs={12} md={9}>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              type="text"
              value={data?.shop_name}
              onChange={(e) => {
                setData({ ...data, name: e.target.value });
              }}
            />
          </Grid>
        </Grid>
        <Grid container className={styles.inputRow} alignItems="center">
          <Grid item xs={12} md={3} spacing={2} justifyContent="flex-end" className={styles.inputLabelContainer}>
            <h3 className={styles.inputLabel}>Mobile no</h3>
          </Grid>
          <Grid item xs={12} md={9}>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              type="number"
              value={data?.mobile_no}
              onChange={(e) => {
                setData({ ...data, mobile_no: Number(e.target.value) });
              }}
            />
          </Grid>
        </Grid>
        <Grid container className={styles.inputRow} alignItems="center">
          <Grid item xs={12} md={3} spacing={2} justifyContent="flex-end" className={styles.inputLabelContainer}>
            <h3 className={styles.inputLabel}>Currency</h3>
          </Grid>
          <Grid item xs={12} md={9}>
            <TextField
              select
              size="small"
              value={data?.currency}
              variant="outlined"
              fullWidth
              color="primary"
              onChange={(event) => {
                setData({ ...data, currency: event.target.value });
              }}
            >
              {CURRENCIES.map((currency) => (
                <MenuItem value={currency}>{currency}</MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
        {shopId && (
          <Grid container className={styles.inputRow} alignItems="center">
            <Grid item xs={12} md={3} spacing={2} justifyContent="flex-end" className={styles.inputLabelContainer}>
              <h3 className={styles.inputLabel}>Ext access token</h3>
            </Grid>
            <Grid item xs={12} md={9}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                value={data?.ext_access_token}
                onChange={(e) => {
                  setData({ ...data, ext_access_token: e.target.value });
                }}
              />
            </Grid>
          </Grid>
        )}
        <Grid container className={styles.inputRow} alignItems="center">
          <Grid item xs={12} md={3} spacing={2} justifyContent="flex-end" className={styles.inputLabelContainer}>
            <h3 className={styles.inputLabel}>Subscription</h3>
          </Grid>
          <Grid item xs={12} md={9}>
            <TextField
              select
              name="fromDate"
              size="small"
              value={SUBSCRIPTION_IDS[data.subscription?.key]}
              variant="outlined"
              fullWidth
              color="primary"
              onChange={(event) => {
                const sub_id = Number(event.target.value);
                setData({
                  ...data,
                  subscription: {
                    ...data.subscription,
                    key: SUBSCRIPTION_KEYS[sub_id],
                    id: sub_id,
                  },
                  subscription_id: sub_id,
                });
              }}
            >
              {Object.keys(SUBSCRIPTIONS).map((subType) => (
                <MenuItem key={subType} value={subType}>
                  {SUBSCRIPTIONS[subType]}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
        {shopId && (
          <Grid container className={styles.inputRow} alignItems="center">
            <Grid item xs={12} md={3} spacing={2} justifyContent="flex-end" className={styles.inputLabelContainer}>
              <h3 className={styles.inputLabel}>Plu sync today</h3>
            </Grid>
            <Grid item xs={12} md={9}>
              <TextField
                variant="outlined"
                size="small"
                type="number"
                fullWidth
                value={data?.plu}
                onChange={(e) => {
                  setData({ ...data, plu: e.target.value });
                }}
              />
            </Grid>
          </Grid>
        )}
        <Grid container className={styles.inputRow} alignItems="center">
          <Grid item xs={12} md={3} spacing={2} justifyContent="flex-end" className={styles.inputLabelContainer}>
            <h3 className={styles.inputLabel}>Dealer</h3>
          </Grid>
          <Grid item xs={12} md={9}>
            <TextField
              select
              size="small"
              value={data?.dealer_id || ''}
              variant="outlined"
              fullWidth
              color="primary"
              onChange={(event) => {
                setData({ ...data, dealer_id: Number(event.target.value) });
              }}
            >
              {dealers.map((dealer) => (
                <MenuItem key={dealer?.id} value={dealer?.id}>
                  {dealer?.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
        {!shopId && (
          <Grid container className={styles.inputRow} alignItems="center">
            <Grid item xs={12} md={3} spacing={2} justifyContent="flex-end" className={styles.inputLabelContainer}>
              <h3 className={styles.inputLabel}>Terminal Type</h3>
            </Grid>
            <Grid item xs={12} md={9}>
              <TextField
                select
                size="small"
                defaultValue={TERMINAL_TYPES.pos || ''}
                variant="outlined"
                fullWidth
                color="primary"
                onChange={(event) => {
                  setData({ ...data, terminal_type: event.target.value });
                }}
              >
                {Object.keys(TERMINAL_TYPES).map((terminal_type) => (
                  <MenuItem key={terminal_type} value={terminal_type}>
                    {TERMINAL_TYPES[terminal_type]}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        )}
        {!shopId && data.terminal_type === 'ecs_terminal' && (
          <>
            <Grid container className={styles.inputRow} alignItems="center">
              <Grid item xs={12} md={3} spacing={2} justifyContent="flex-end" className={styles.inputLabelContainer}>
                <h3 className={styles.inputLabel}>MAC ID</h3>
              </Grid>
              <Grid item xs={12} md={9}>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={data?.mac_id}
                  onChange={(e) => {
                    setData({ ...data, mac_id: e.target.value });
                  }}
                />
              </Grid>
            </Grid>
            <Grid container className={styles.inputRow} alignItems="center">
              <Grid item xs={12} md={3} spacing={2} justifyContent="flex-end" className={styles.inputLabelContainer}>
                <h3 className={styles.inputLabel}>Next FID</h3>
              </Grid>
              <Grid item xs={12} md={9}>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={data?.next_fid}
                  onChange={(e) => {
                    setData({ ...data, next_fid: Number(e.target.value) });
                  }}
                />
              </Grid>
            </Grid>
          </>
        )}
        <Grid container className={styles.inputRow} alignItems="center">
          <Grid item xs={12} md={3} spacing={2} justifyContent="flex-end" className={styles.inputLabelContainer}>
            <h3 className={styles.inputLabel}>Subscription end date</h3>
          </Grid>
          <Grid item xs={12} md={9}>
            <Grid container>
              <Grid item xs={3} md={5} lg={6} className={styles.subText}>
                <Typography variant="subtitle2" pt={1.5} gutterBottom>
                  {data?.subscription?.validity} days
                </Typography>
                {data?.subscription_end_date && (
                  <Typography variant="subtitle2" pt={1.5} gutterBottom>
                    {data?.subscription_end_date}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={9} md={7} lg={6} className={styles.btnAddYear}>
                <Button
                  variant="contained"
                  onClick={() => handleSubscriptionValidity(0)}
                  color="primary"
                  disabled={!data.subscription?.validity}
                  style={{ marginRight: '10px' }}
                >
                  Clear
                </Button>
                <Button
                  variant="contained"
                  onClick={() => handleSubscriptionValidity(MONTHLY_SUB_PERIOD)}
                  color="secondary"
                  style={{ marginRight: '5px' }}
                >
                  Month
                </Button>
                <Button
                  variant="contained"
                  onClick={() => handleSubscriptionValidity(YEARLY_SUB_PERIOD)}
                  color="secondary"
                >
                  Year
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container className={styles.inputRow} alignItems="center">
          <Grid item xs={12} md={3} spacing={2} justifyContent="flex-end" className={styles.inputLabelContainer}>
            <h3 className={styles.inputLabel}>Kot validity</h3>
          </Grid>
          <Grid item xs={12} md={9}>
            <Grid container>
              <Grid item xs={3} md={5} lg={6} className={styles.subText}>
                <Typography variant="subtitle2" pt={1.5}>
                  {data?.add_ons?.kot || 0} days
                </Typography>
                {data?.kot_validity && (
                  <Typography variant="subtitle2" pt={1.5}>
                    {data?.kot_validity}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={9} md={7} lg={6} className={styles.btnAddYear}>
                <Button
                  variant="contained"
                  onClick={() => handleAddonValidity('kot', 0)}
                  color="primary"
                  disabled={!data.add_ons?.kot}
                  style={{ marginRight: '10px' }}
                >
                  Clear
                </Button>
                <Button
                  variant="contained"
                  onClick={() => handleAddonValidity('kot', MONTHLY_SUB_PERIOD)}
                  color="secondary"
                  style={{ marginRight: '5px' }}
                >
                  Month
                </Button>
                <Button
                  variant="contained"
                  onClick={() => handleAddonValidity('kot', YEARLY_SUB_PERIOD)}
                  color="secondary"
                >
                  Year
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container className={styles.inputRow} alignItems="center">
          <Grid item xs={12} md={3} spacing={2} justifyContent="flex-end" className={styles.inputLabelContainer}>
            <h3 className={styles.inputLabel}>KOT lite validity</h3>
          </Grid>
          <Grid item xs={12} md={9}>
            <Grid container>
              <Grid item xs={3} md={5} lg={6} className={styles.subText}>
                <Typography variant="subtitle2" pt={1.5} gutterBottom>
                  {data?.add_ons?.kot_lite || 0} days
                </Typography>
                {data?.kot_lite_validity && (
                  <Typography variant="subtitle2" pt={1.5} gutterBottom>
                    {data?.kot_lite_validity}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={9} md={7} lg={6} className={styles.btnAddYear}>
                <Button
                  variant="contained"
                  onClick={() => handleAddonValidity('kot_lite', 0)}
                  color="primary"
                  disabled={!data.add_ons?.kot_lite}
                  style={{ marginRight: '10px' }}
                >
                  Clear
                </Button>
                <Button
                  variant="contained"
                  onClick={() => handleAddonValidity('kot_lite', MONTHLY_SUB_PERIOD)}
                  color="secondary"
                  style={{ marginRight: '5px' }}
                >
                  Month
                </Button>
                <Button
                  variant="contained"
                  onClick={() => handleAddonValidity('kot_lite', YEARLY_SUB_PERIOD)}
                  color="secondary"
                >
                  Year
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container className={styles.inputRow} alignItems="center">
          <Grid item xs={12} md={3} spacing={2} justifyContent="flex-end" className={styles.inputLabelContainer}>
            <h3 className={styles.inputLabel}>Image support validity</h3>
          </Grid>
          <Grid item xs={12} md={9}>
            <Grid container>
              <Grid item xs={3} md={5} lg={6} className={styles.subText}>
                <Typography variant="subtitle2" pt={1.5} gutterBottom>
                  {data?.add_ons?.image || 0} days
                </Typography>
                {data?.image_support_validity && (
                  <Typography variant="subtitle2" pt={1.5} gutterBottom>
                    {data?.image_support_validity}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={9} md={7} lg={6} className={styles.btnAddYear}>
                <Button
                  variant="contained"
                  onClick={() => handleAddonValidity('image', 0)}
                  color="primary"
                  disabled={!data.add_ons?.image}
                  style={{ marginRight: '10px' }}
                >
                  Clear
                </Button>
                <Button
                  variant="contained"
                  onClick={() => handleAddonValidity('image', MONTHLY_SUB_PERIOD)}
                  color="secondary"
                  style={{ marginRight: '5px' }}
                >
                  Month
                </Button>
                <Button
                  variant="contained"
                  onClick={() => handleAddonValidity('image', YEARLY_SUB_PERIOD)}
                  color="secondary"
                >
                  Year
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container className={styles.inputRow} alignItems="center">
          <Grid item xs={12} md={3} spacing={2} justifyContent="flex-end" className={styles.inputLabelContainer}>
            <h3 className={styles.inputLabel}>External API support validity</h3>
          </Grid>
          <Grid item xs={12} md={9}>
            <Grid container>
              <Grid item xs={3} md={5} lg={6} className={styles.subText}>
                <Typography variant="subtitle2" pt={1.5} gutterBottom>
                  {data?.add_ons?.external_api || 0} days
                </Typography>
                {data?.quickbook_support_validity && (
                  <Typography variant="subtitle2" pt={1.5} gutterBottom>
                    {data?.quickbook_support_validity}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={9} md={7} lg={6} className={styles.btnAddYear}>
                <Button
                  variant="contained"
                  onClick={() => handleAddonValidity('external_api', 0)}
                  color="primary"
                  disabled={!data.add_ons?.external_api}
                  style={{ marginRight: '10px' }}
                >
                  Clear
                </Button>
                <Button
                  variant="contained"
                  onClick={() => handleAddonValidity('external_api', MONTHLY_SUB_PERIOD)}
                  color="secondary"
                  style={{ marginRight: '5px' }}
                >
                  Month
                </Button>
                <Button
                  variant="contained"
                  onClick={() => handleAddonValidity('external_api', YEARLY_SUB_PERIOD)}
                  color="secondary"
                >
                  Year
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container className={styles.inputRow} alignItems="center">
          <Grid item xs={12} md={3} spacing={2} justifyContent="flex-end" className={styles.inputLabelContainer}>
            <h3 className={styles.inputLabel}>Customer support validity</h3>
          </Grid>
          <Grid item xs={12} md={9}>
            <Grid container>
              <Grid item xs={3} md={5} lg={6} className={styles.subText}>
                <Typography variant="subtitle2" pt={1.5} gutterBottom>
                  {data?.add_ons?.customer || 0} days
                </Typography>
                {data?.customer_support_validity && (
                  <Typography variant="subtitle2" pt={1.5} gutterBottom>
                    {data?.customer_support_validity}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={9} md={7} lg={6} className={styles.btnAddYear}>
                <Button
                  variant="contained"
                  onClick={() => handleAddonValidity('customer', 0)}
                  color="primary"
                  disabled={!data.add_ons?.customer}
                  style={{ marginRight: '10px' }}
                >
                  Clear
                </Button>
                <Button
                  variant="contained"
                  onClick={() => handleAddonValidity('customer', MONTHLY_SUB_PERIOD)}
                  color="secondary"
                  style={{ marginRight: '5px' }}
                >
                  Month
                </Button>
                <Button
                  variant="contained"
                  onClick={() => handleAddonValidity('customer', YEARLY_SUB_PERIOD)}
                  color="secondary"
                >
                  Year
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container className={styles.inputRow} alignItems="center">
          <Grid item xs={12} md={3} spacing={2} justifyContent="flex-end" className={styles.inputLabelContainer}>
            <h3 className={styles.inputLabel}>Bluetooth support validity</h3>
          </Grid>
          <Grid item xs={12} md={9}>
            <Grid container>
              <Grid item xs={3} md={5} lg={6} className={styles.subText}>
                <Typography variant="subtitle2" pt={1.5} gutterBottom>
                  {data?.add_ons?.bluetooth_scale || 0} days
                </Typography>
                {data?.bluetooth_scale_validity && (
                  <Typography variant="subtitle2" pt={1.5} gutterBottom>
                    {data?.bluetooth_scale_validity}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={9} md={7} lg={6} className={styles.btnAddYear}>
                <Button
                  variant="contained"
                  onClick={() => handleAddonValidity('bluetooth_scale', 0)}
                  color="primary"
                  disabled={!data.add_ons?.bluetooth_scale}
                  style={{ marginRight: '10px' }}
                >
                  Clear
                </Button>
                <Button
                  variant="contained"
                  onClick={() => handleAddonValidity('bluetooth_scale', MONTHLY_SUB_PERIOD)}
                  color="secondary"
                  style={{ marginRight: '5px' }}
                >
                  Month
                </Button>
                <Button
                  variant="contained"
                  onClick={() => handleAddonValidity('bluetooth_scale', YEARLY_SUB_PERIOD)}
                  color="secondary"
                >
                  Year
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container className={styles.inputRow} alignItems="center">
          <Grid item xs={12} md={3} spacing={2} justifyContent="flex-end" className={styles.inputLabelContainer}>
            <h3 className={styles.inputLabel}>Smart scale validity</h3>
          </Grid>
          <Grid item xs={12} md={9}>
            <Grid container>
              <Grid item xs={3} md={5} lg={6} className={styles.subText}>
                <Typography variant="subtitle2" pt={1.5} gutterBottom>
                  {data?.add_ons?.smart_scale || 0} days
                </Typography>
                {data?.smart_scale_validity && (
                  <Typography variant="subtitle2" pt={1.5} gutterBottom>
                    {data?.smart_scale_validity}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={9} md={7} lg={6} className={styles.btnAddYear}>
                <Button
                  variant="contained"
                  onClick={() => handleAddonValidity('smart_scale', 0)}
                  color="primary"
                  disabled={!data.add_ons?.smart_scale}
                  style={{ marginRight: '10px' }}
                >
                  Clear
                </Button>
                <Button
                  variant="contained"
                  onClick={() => handleAddonValidity('smart_scale', MONTHLY_SUB_PERIOD)}
                  color="secondary"
                  style={{ marginRight: '5px' }}
                >
                  Month
                </Button>
                <Button
                  variant="contained"
                  onClick={() => handleAddonValidity('smart_scale', YEARLY_SUB_PERIOD)}
                  color="secondary"
                >
                  Year
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container className={styles.inputRow} alignItems="center">
          <Grid item xs={12} md={3} spacing={2} justifyContent="flex-end" className={styles.inputLabelContainer}>
            <h3 className={styles.inputLabel}>Rt sales sync interval</h3>
          </Grid>
          <Grid item xs={12} md={9}>
            <TextField
              variant="outlined"
              size="small"
              type="number"
              fullWidth
              value={data?.rt_sales_sync_interval}
              onChange={(e) => {
                setData({ ...data, rt_sales_sync_interval: e.target.value });
              }}
            />
          </Grid>
        </Grid>
        <Grid container className={styles.inputRow} alignItems="center">
          <Grid item xs={12} md={3} spacing={2} justifyContent="flex-end" className={styles.inputLabelContainer}>
            <h3 className={styles.inputLabel}>Additional Sales Balance</h3>
          </Grid>
          <Grid item xs={12} md={9}>
            <TextField
              select
              size="small"
              value={data?.additional_sales?.count}
              variant="outlined"
              fullWidth
              color="primary"
              onChange={(event) => {
                const { value } = event.target;
                setData((prevData) => ({
                  ...prevData,
                  additional_sales: value ?? null,
                }));
              }}
            >
              <MenuItem value={null}>None</MenuItem>
              {ADDITIONAL_SALES.map((additional_sale) => (
                <MenuItem value={additional_sale}>{additional_sale.count}</MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
        <Grid container className={styles.inputRow} alignItems="center">
          <Grid item xs={12} md={3} spacing={2} justifyContent="flex-end" className={styles.inputLabelContainer}>
            <h3 className={styles.inputLabel}>Remarks</h3>
          </Grid>
          <Grid item xs={12} md={9}>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={data?.remark}
              onChange={(e) => {
                setData({ ...data, remark: e.target.value });
              }}
            />
          </Grid>
        </Grid>
        {shopId && terminals.length && (
          <>
            <div className={styles.filterSec}>
              <div className={styles.headTitle}>
                <h2 className={styles.subTitle}>Terminals</h2>
              </div>
            </div>
            <Grid container className={styles.inputRow} alignItems="center">
              {terminals.map((terminal) => {
                return (
                  <>
                    <Grid item xs={6}>
                      <h3 className={styles.inputLabel}>{terminal?.name}</h3>
                    </Grid>
                    <Grid item xs={3}>
                      <h3 className={styles.inputLabel}>{terminal?.type}</h3>
                    </Grid>
                    <Grid item xs={3}>
                      <FormControlLabel
                        value="end"
                        control={
                          <Checkbox
                            color="primary"
                            checked={addonTerminals.hasOwnProperty(terminal.name)}
                            onChange={() => {
                              handleAdditionalTerminals(terminal);
                            }}
                          />
                        }
                        labelPlacement="end"
                      />
                    </Grid>
                  </>
                );
              })}
            </Grid>
          </>
        )}
        {!shopId && (
          <>
            <div className={styles.filterSec}>
              <div className={styles.headTitle}>
                <h2 className={styles.subTitle}>User Details</h2>
              </div>
            </div>
            <Grid container className={styles.inputRow} alignItems="center">
              <Grid item xs={12} md={3} spacing={2} justifyContent="flex-end" className={styles.inputLabelContainer}>
                <h3 className={styles.inputLabel}>First name</h3>
              </Grid>
              <Grid item xs={12} md={9}>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  type="text"
                  value={data?.first_name}
                  onChange={(e) => {
                    setData({ ...data, first_name: e.target.value });
                  }}
                />
              </Grid>
            </Grid>
            <Grid container className={styles.inputRow} alignItems="center">
              <Grid item xs={12} md={3} spacing={2} justifyContent="flex-end" className={styles.inputLabelContainer}>
                <h3 className={styles.inputLabel}>Last name</h3>
              </Grid>
              <Grid item xs={12} md={9}>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  type="text"
                  value={data?.second_name}
                  onChange={(e) => {
                    setData({ ...data, last_name: e.target.value });
                  }}
                />
              </Grid>
            </Grid>
            <Grid container className={styles.inputRow} alignItems="center">
              <Grid item xs={12} md={3} spacing={2} justifyContent="flex-end" className={styles.inputLabelContainer}>
                <h3 className={styles.inputLabel}>User name</h3>
              </Grid>
              <Grid item xs={12} md={9}>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  type="text"
                  value={data?.username}
                  onChange={(e) => {
                    setData({ ...data, username: e.target.value });
                  }}
                />
              </Grid>
            </Grid>
            <Grid container className={styles.inputRow} alignItems="center">
              <Grid item xs={12} md={3} spacing={2} justifyContent="flex-end" className={styles.inputLabelContainer}>
                <h3 className={styles.inputLabel}>Password</h3>
              </Grid>
              <Grid item xs={12} md={9}>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  type="text"
                  value={data?.password}
                  onChange={(e) => {
                    setData({ ...data, password: e.target.value });
                  }}
                />
              </Grid>
            </Grid>
          </>
        )}
      </div>
      <div>
        <Grid container className={styles.submitSection} justifyContent="flex-end">
          <Link to={ROUTES.SHOPS} className={styles.link}>
            <Button variant="contained" color="secondary" className={styles.closeBtn}>
              Cancel
            </Button>
          </Link>
          <Button
            variant="contained"
            color="primary"
            style={{ backgroundColor: '#00a65a', marginLeft: '10px' }}
            onClick={handleSubmit}
          >
            {data.id ? 'Save Changes' : 'Create Shop'}
          </Button>
        </Grid>
      </div>
    </div>
  );
}

export default withConsoleBase(NewShop);
