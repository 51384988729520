import BackendService from './BackendService';

async function createTerminal(data) {
  return BackendService.postData('/admin/terminals/', data);
}

async function getTerminals(params={}) {
  return BackendService.getData('/admin/terminals/', {}, params)
}

const ShopServices = {
  createTerminal,
  getTerminals,
};

export default ShopServices;
